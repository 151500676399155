import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

let routes = [
	{
		// will match everything
		path: '*',
		component: () => import('../views/404.vue'),
	},
	{
		path: '/',
		name: 'Home',
		redirect: '/login',
	},
	{
		path: '/overview',
		name: 'Overview',
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
		beforeEnter: (to, from, next) => {
			if (localStorage.getItem('token')) {
				next()
			} else {

				next({
					path: '/', // back to safety
					query: {
						redirectFrom: to.fullPath
					}
				})
			}
		}
	},
	{
		path: '/layout',
		name: 'Layout',
		layout: "dashboard",
		component: () => import('../views/Layout.vue'),
		beforeEnter: (to, from, next) => {
			if (localStorage.getItem('token')) {
				next()
			} else {

				next({
					path: '/', // back to safety
					query: {
						redirectFrom: to.fullPath
					}
				})
			}
		}
	},
	{
		path: '/transactions',
		name: 'Transactions',
		layout: "dashboard",
		component: () => import('../views/Tables.vue'),
		beforeEnter: (to, from, next) => {
			if (localStorage.getItem('token')) {
				next()
			} else {

				next({
					path: '/', // back to safety
					query: {
						redirectFrom: to.fullPath
					}
				})
			}
		}
	},
	{
		path: '/card-payments',
		name: 'Card & Payments',
		layout: "dashboard",
		component: () => import('../views/Billing.vue'),
		beforeEnter: (to, from, next) => {
			if (localStorage.getItem('token')) {
				next()
			} else {

				next({
					path: '/', // back to safety
					query: {
						redirectFrom: to.fullPath
					}
				})
			}
		}
	},
	{
		path: '/rtl',
		name: 'RTL',
		layout: "dashboard-rtl",
		meta: {
			layoutClass: 'dashboard-rtl',
		},
		component: () => import('../views/RTL.vue'),
	},
	{
		path: '/send-money',
		name: 'Send Money',
		layout: "dashboard",
		meta: {
			layoutClass: 'layout-profile',
		},
		component: () => import('../views/Profile.vue'),
		beforeEnter: (to, from, next) => {
			if (localStorage.getItem('token')) {
				next()
			} else {

				next({
					path: '/', // back to safety
					query: {
						redirectFrom: to.fullPath
					}
				})
			}
		}
	},
	{
		path: '/login',
		name: 'Log-In',
		component: () => import('../views/Sign-In.vue'),
		props: true,
		beforeEnter: (to, from, next) => {
			localStorage.setItem("guard", "1")
			if (!localStorage.getItem('token')) {
				localStorage.setItem("guard", "0")
				next()
				// }else if(!localStorage.getItem('userLoggedIn')){
				// next()
			} else {

				next({
					path: '/overview', // back to safety
					query: {
						redirectFrom: to.fullPath
					}
				})
			}
		}
	},
	{
		path: '/create-account',
		name: 'Create-Account',
		meta: {
			layoutClass: 'layout-sign-up',
		},
		component: () => import('../views/Sign-Up.vue'),
		props: true,
		beforeEnter: (to, from, next) => {
			localStorage.setItem("guard", "1")
			if (!localStorage.getItem('token')) {
				localStorage.setItem("guard", "0")
				next()
				// }else if(!localStorage.getItem('userLoggedIn')){
				// next()
			} else {

				next({
					path: '/overview', // back to safety
					query: {
						redirectFrom: to.fullPath
					}
				})
			}
		}
	},
]

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute( route, parentLayout = "default" )
{
	route.meta = route.meta || {} ;
	route.meta.layout = route.layout || parentLayout ;
	
	if( route.children )
	{
		route.children = route.children.map( ( childRoute ) => addLayoutToRoute( childRoute, route.meta.layout ) ) ;
	}
	return route ;
}

routes = routes.map( ( route ) => addLayoutToRoute( route ) ) ;

const router = new VueRouter({
	// mode: 'hash',
	mode: 'history',
	hash: false,
	base: process.env.BASE_URL,
	routes,
	// scrollBehavior (to, from, savedPosition) {
	// 	if ( to.hash ) {
	// 		return {
	// 			selector: to.hash,
	// 			behavior: 'smooth',
	// 		}
	// 	}
	// 	return {
	// 		x: 0,
	// 		y: 0,
	// 		behavior: 'smooth',
	// 	}
	// }
})

export default router
